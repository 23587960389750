import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import {initRootFontSize} from './uits/initRootFontSize.js'
import 'amfe-flexible'
import 'view-design/dist/styles/iview.css';
import 'vant/lib/index.css';
import './uits/iview.js'
import './uits/vant.js'
import Particles from "@tsparticles/vue2";
import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
// import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
Vue.use(Particles, {
  init: async engine => {
      await loadFull(engine);
      // await loadSlim(engine);
  },
});

initRootFontSize()
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
